import React, { useContext } from "react";
import { AppContext } from "../shared/context/AppContext";

function convertMs(timeInMs) {
  let hours = Math.floor((timeInMs / (1000 * 60 * 60)) % 60);
  let minutes = Math.floor((timeInMs / (1000 * 60)) % 60);
  let seconds = Math.floor((timeInMs / 1000) % 60);
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  return `${hours}:${minutes}:${seconds}`;
}

function KlassementTable({ data }) {
  const { language } = useContext(AppContext);

  const KlassementSorted = data.sort((a, b) =>
    parseFloat(a.totaltime - parseFloat(b.totaltime))
  );
  const tablerows = KlassementSorted.map((team, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{team.teamname}</td>
        <td>{convertMs(team.totaltime)}</td>
      </tr>
    );
  });

  return (
    <table id="rankings">
      <thead>
        <tr>
          <th></th>
          <th>Team</th>
          <th>{language === "NL" ? "Tijd" : "Time"}</th>
        </tr>
      </thead>
      <tbody>{tablerows}</tbody>
    </table>
  );
}

export default KlassementTable;
