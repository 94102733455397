import React from "react";
import "./Privacy.css";
import { Helmet } from "react-helmet";

function Privacy() {
  return (
    <div className="page" id="privacy">
      <Helmet>
        <title>Privacy Policy | Escape Mystery</title>
      </Helmet>
      <section className="page-content">
        <h2 className="centeralign">Privacy Statement Escape Mystery</h2>
        <div className="textClean">
          Escape Mystery VOF, gevestigd te Rotterdam, is verantwoordelijk voor
          de verwerking van persoonsgegevens zoals weergegeven in deze
          privacyverklaring.
          <h3>Contactgegevens</h3>
          <ul>
            <li>https://www.escapemystery.nl</li>
            <li>info@escapemystery.nl</li>
          </ul>
          <h3>Persoonsgegevens die wij verwerken</h3>
          Escape Mystery VOF verwerkt je persoonsgegevens doordat je gebruik
          maakt van onze diensten en/of omdat je deze gegevens zelf aan ons
          verstrekt. Hieronder vind je een overzicht van de persoonsgegevens die
          wij verwerken:
          <ul>
            <li>Voor- en achternaam</li>
            <li>E-mailadres</li>
            <li>
              Overige persoonsgegevens die je actief verstrekt bijvoorbeeld door
              een profiel op deze website aan te maken, in correspondentie en
              telefonisch
            </li>
            <li>Bankrekeningnummer</li>
          </ul>
          <h3>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h3>
          Escape Mystery VOF verwerkt de volgende bijzondere en/of gevoelige
          persoonsgegevens van jou:
          <ul>
            <li>mogelijk gegevens van personen jonger dan 16 jaar</li>
          </ul>
          Onze website en/of dienst heeft niet de intentie gegevens te
          verzamelen over websitebezoekers die jonger zijn dan 16 jaar, tenzij
          ze toestemming hebben van ouders of voogd. We kunnen echter niet
          controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook
          aan betrokken te zijn bij de online activiteiten van hun kinderen, om
          zo te voorkomen dat er gegevens over kinderen verzameld worden zonder
          ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder
          die toestemming persoonlijke gegevens hebben verzameld over een
          minderjarige, neem dan contact met ons op via info@escapemystery.nl,
          dan verwijderen wij deze informatie.
          <h3>
            Met welk doel en op basis van welke grondslag wij persoonsgegevens
            verwerken
          </h3>
          Escape Mystery VOF verwerkt jouw persoonsgegevens voor de volgende
          doelen:
          <ul>
            <li>Het afhandelen van jouw betaling</li>{" "}
            <li>Verzenden van onze nieuwsbrief en/of reclamefolder</li>{" "}
            <li>
              Je te kunnen bellen of e-mailen indien dit nodig is om onze
              dienstverlening uit te kunnen voeren
            </li>{" "}
            <li>
              Je te informeren over wijzigingen van onze diensten en producten
            </li>{" "}
            <li>Om digitale diensten bij je af te leveren</li>{" "}
            <li>
              Escape Mystery VOF verwerkt ook persoonsgegevens als wij hier
              wettelijk toe verplicht zijn, zoals gegevens die wij nodig hebben
              voor onze belastingaangifte.{" "}
            </li>
          </ul>
          <h3>Geautomatiseerde besluitvorming</h3>
          Escape Mystery VOF neemt op basis van geautomatiseerde verwerkingen
          geen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben
          voor personen.
          <h3>Hoe lang we persoonsgegevens bewaren</h3>
          Escape Mystery VOF bewaart je persoonsgegevens niet langer dan strikt
          nodig is om de doelen te realiseren waarvoor je gegevens worden
          verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende
          (categorieën) van persoonsgegevens:
          <table>
            <tr>
              <th>
                <b>Categorie</b>
              </th>
              <th>
                <b>Bewaartermijn</b>
              </th>
            </tr>
            <tr>
              <td>Voor- en Achternaam</td>
              <td>1 jaar</td>
            </tr>
            <tr>
              <td>E-mailadres</td>
              <td>
                Totdat je uitschrijft voor de nieuwsbrief, anders maximaal 1
                jaar
              </td>
            </tr>
            <tr>
              <td>
                Overige persoonsgegevens die je actief verstrekt bijvoorbeeld
                door een profiel op deze website aan te maken, in
                correspondentie en telefonisch
              </td>
              <td>1 jaar</td>
            </tr>
            <tr>
              <td>Bankrekeningnummer</td>
              <td>1 jaar</td>
            </tr>
          </table>
          <h3>Delen van persoonsgegevens met derden</h3>
          Escape Mystery verstrekt jouw gegevens uitsluitend en alleen aan
          derden als dit nodig is voor de uitvoering van onze overeenkomst met
          jou of om te voldoen aan een wettelijke verplichting.
          <h3>Cookies, of vergelijkbare technieken, die wij gebruiken</h3>
          Escape Mystery VOF gebruikt alleen technische en functionele cookies
          en analytische cookies die geen inbreuk maken op je privacy. Een
          cookie is een klein tekstbestand dat bij het eerste bezoek aan deze
          website wordt opgeslagen op jouw computer, tablet of smartphone. De
          cookies die wij gebruiken zijn noodzakelijk voor de technische werking
          van de website en jouw gebruiksgemak. Ze zorgen ervoor dat de website
          naar behoren werkt en onthouden bijvoorbeeld jouw
          voorkeursinstellingen. Ook kunnen wij hiermee onze website
          optimaliseren. Je kunt je afmelden voor cookies door je
          internetbrowser zo in te stellen dat deze geen cookies meer opslaat.
          Daarnaast kun je ook alle informatie die eerder is opgeslagen via de
          instellingen van je browser verwijderen.
          <h3>Gegevens inzien, aanpassen of verwijderen</h3>
          Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of
          te verwijderen. Daarnaast heb je het recht om je eventuele toestemming
          voor de gegevensverwerking in te trekken of bezwaar te maken tegen de
          verwerking van jouw persoonsgegevens door Escape Mystery VOF en heb je
          het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons
          een verzoek kan indienen om de persoonsgegevens die wij van jou
          beschikken in een computerbestand naar jou of een ander, door jou
          genoemde organisatie, te sturen. Je kunt een verzoek tot inzage,
          correctie, verwijdering, gegevensoverdraging van je persoonsgegevens
          of verzoek tot intrekking van je toestemming of bezwaar op de
          verwerking van jouw persoonsgegevens sturen naar
          info@escapemystery.nl. Om er zeker van te zijn dat het verzoek tot
          inzage door jou is gedaan, vragen wij jou een kopie van je
          identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je
          pasfoto, MRZ (machine readable zone, de strook met nummers onderaan
          het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit
          ter bescherming van je privacy. We reageren zo snel mogelijk, maar
          binnen vier weken, op jouw verzoek. Escape Mystery wil je er tevens op
          wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de
          nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via
          de volgende link:
          https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
          <h3>Hoe wij persoonsgegevens beveiligen</h3>
          Escape Mystery VOF neemt de bescherming van jouw gegevens serieus en
          neemt passende maatregelen om misbruik, verlies, onbevoegde toegang,
          ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan.
          Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn
          of er aanwijzingen zijn van misbruik, neem dan contact op met onze
          klantenservice of via info@escapemystery.nl
        </div>
      </section>
    </div>
  );
}

export default Privacy;
