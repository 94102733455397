import React from "react";
import "./DiffIcon.css";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function DiffIcon({ diff, id }) {
  let diffIconDiv = [];
  for (let i = 0; i < 5; i++) {
    diffIconDiv[i] =
      i < diff ? (
        <div className={`lockIconBig ${id}-color`} key={i}>
          <FontAwesomeIcon icon={faLock} />{" "}
        </div>
      ) : (
        <div
          className="lockIconBig"
          key={i}
          style={{ color: "rgb(255, 255, 255, 0.5)" }}
        >
          <FontAwesomeIcon icon={faLock} />{" "}
        </div>
      );
  }

  return <div className="DiffIconBig">{diffIconDiv}</div>;
}

export default DiffIcon;
