// Import package dependencies
import React, { Fragment, useState, useEffect } from "react";

// Import routing requirements
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Import Context
import { AppContext } from "../shared/context/AppContext";

// Import components for the main App page
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import HomepageBody from "../Homepage/HomepageBody";
import EscapeRooms from "../EscaperoomsPage/EscapeRooms";
import Zakelijk from "../Bedrijven/Zakelijk";
// import Competition from "../Competition/Competition";
import FAQ from "../FAQ/FAQ";
import AlgemeneVoorwaarden from "../AlgemeneVW/AlgemeneVoorwaarden";
import Privacy from "../Privacy/Privacy";
import PayPage from "../PayPages/PayPage";
// import PayOECER from "../PayPages/PayOECER";
import PaymentComplete from "../PaymentComplete/PaymentComplete";
// import PaymentCompleteOEC from "../PaymentComplete/PaymentCompleteOEC";
import PageNotFound from "../PageNotFound/PageNotFound";
import ScrollToTop from "../shared/ScrollToTop";
import Klassement from "../Klassement/Klassement";
// import OECRanking from "../OECRanking/OECRanking";
import InfoPage from "../InfoPages/InfoPage.js";

// global App CSS files import
import "./Resets.css";
import "./App.css";

function App() {
  const [language, setLanguage] = useState("");
  const [activeHeader, setActiveHeader] = useState("");
  const [dotcom, setDotcom] = useState(false);

  useEffect(() => {
    if (window.location.hostname === "escape-mystery.com") {
      setDotcom(true);
    } else {
      setDotcom(false);
    }
  }, [setDotcom]);

  useEffect(() => {
    const lang = window.localStorage.getItem("lang");
    if (lang === "EN" || dotcom) {
      setLanguage("EN");
      window.localStorage.setItem("lang", "EN");
    } else {
      setLanguage("NL");
      window.localStorage.setItem("lang", "NL");
    }
  }, [dotcom]);

  return (
    <Router>
      <AppContext.Provider
        value={{
          language: language,
          setLanguage: setLanguage,
          activeHeader: activeHeader,
          setActiveHeader: setActiveHeader,
          dotcom: dotcom,
        }}
      >
        <Fragment>
          <ScrollToTop />
          <Header />
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => <HomepageBody {...props} />}
            />
            <Route
              path="/escaperooms"
              exact
              render={(props) => <EscapeRooms {...props} />}
            />
            <Route path="/faq" exact render={(props) => <FAQ {...props} />} />
            <Route
              path="/custom_escape_room"
              exact
              render={(props) => <Zakelijk {...props} />}
            />

            {/* <Route
              path="/online_escape_competition"
              exact
              render={(props) => <Competition {...props} />}
            /> */}

            {/* <Route
              path="/online_escape_competition/rankings"
              exact
              render={(props) => <OECRanking {...props} />}
            /> */}

            <Route
              path="/voorwaarden"
              exact
              render={(props) => <AlgemeneVoorwaarden {...props} />}
            />
            <Route
              path="/privacy"
              exact
              render={(props) => <Privacy {...props} />}
            />

            {/* Escaperoom info pages */}
            {/* <Route
              path="/escaperooms/info/miniescaperoom"
              exact
              render={(props) => <InfoMiniEscaperoom {...props} />}
            /> */}

            <Route
              path="/escaperooms/info/:linktoPar"
              exact
              render={(props) => <InfoPage {...props} />}
            />

            <Route
              path="/escaperooms/pay/:linktoPar"
              exact
              render={(props) => <PayPage {...props} />}
            />

            <Route
              path="/klassementen/:naam"
              exact
              render={(props) => <Klassement {...props} />}
            />

            <Route
              path="/paymentcomplete/:orderId/:ERid"
              exact
              render={(props) => <PaymentComplete {...props} />}
            />
            {/* <Route
              path="/paymentcompleteOEC/:orderId"
              exact
              render={(props) => <PaymentCompleteOEC {...props} />}
            /> */}
            <Route path="/" render={(props) => <PageNotFound {...props} />} />
          </Switch>
          <Footer />
        </Fragment>
      </AppContext.Provider>
    </Router>
  );
}

export default App;
