import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppContext } from "../shared/context/AppContext";

function PageNotFound() {
  const { language } = useContext(AppContext);
  return (
    <div className="PageNotFound page">
      <Helmet>
        <title>
          {language === "NL" ? "Pagina niet gevonden" : "Page not found"} |
          Escape Mystery
        </title>
      </Helmet>
      <section className="page-content">
        <h2 className="centeralign">
          {language === "NL" ? "Pagina niet gevonden" : "Page not found"}
        </h2>
        <p className="centeralign">
          {language === "NL"
            ? "Je bent lekker aan het speuren op onze website, maar deze pagina bestaat helaas niet."
            : "This page does not exists."}
        </p>
        <Link to="/">
          <div className="blue-button vert-margin">
            {language === "NL"
              ? "Terug naar Escape Mystery"
              : "Back to Escape Mystery"}
          </div>
        </Link>
      </section>
    </div>
  );
}

export default PageNotFound;
