import React, { Fragment, useContext, useEffect } from "react";
import "./Header.css";
import dots from "./img/dots.png";
import { Link } from "react-router-dom";

import { AppContext } from "../shared/context/AppContext";

function Header() {
  const { language, setLanguage, activeHeader, dotcom } =
    useContext(AppContext);

  function handleClickThreeDots() {
    var x = document.getElementById("header");
    if (x.className === "") {
      x.className += "responsive";
    } else {
      x.className = "";
    }
  }

  useEffect(() => {
    if (!dotcom) {
      if (language === "NL") {
        document.getElementById("lan-NL").classList.add("active");
        document.getElementById("lan-EN").classList.remove("active");
      } else {
        document.getElementById("lan-EN").classList.add("active");
        document.getElementById("lan-NL").classList.remove("active");
      }
    }
  }, [language]);

  useEffect(() => {
    try {
      const navButtons = document.getElementsByClassName("navButton");
      for (let i = 0; i < navButtons.length; i++) {
        navButtons[i].classList.remove("active");
      }
    } catch {}
    try {
      document.getElementById(`nav-${activeHeader}`).classList.add("active");
    } catch {}
  }, [activeHeader]);

  const handleToNL = () => {
    setLanguage("NL");
    window.localStorage.setItem("lang", "NL");
  };

  const handleToEN = () => {
    setLanguage("EN");
    window.localStorage.setItem("lang", "EN");
  };

  function handleClick() {
    handleClickThreeDots();
  }

  return (
    <Fragment>
      <div id="header">
        <Link to="/" onClick={handleClick}>
          <h3 id="nav-home" className="navButton">
            Home
          </h3>
        </Link>
        <Link to="/escaperooms" onClick={handleClick}>
          <h3 id="nav-escaperooms" className="navButton">
            Escape rooms
          </h3>
        </Link>
        {/* <Link to="/online_escape_competition" onClick={handleClick}>
          <h3 id="nav-online_escape_competition" className="navButton">
            {language === "NL" ? "Escape Competitie" : "Escape Competition"}
          </h3>
        </Link> */}
        <Link to="/custom_escape_room" onClick={handleClick}>
          <h3 id="nav-zakelijk" className="navButton">
            {language === "NL" ? "Escape room op maat" : "Custom escape room"}
          </h3>
        </Link>
        <Link to="/faq" onClick={handleClick}>
          <h3 id="nav-faq" className="navButton">
            FAQ
          </h3>
        </Link>

        {!dotcom && (
          <div id="language-toggle" className="navButton">
            <span id="lan-NL" className="active" onClick={handleToNL}>
              &#127475;&#127473;
            </span>{" "}
            /{" "}
            <span id="lan-EN" onClick={handleToEN}>
              &#127468;&#127463;
            </span>
          </div>
        )}
        <div className="dropdown-toggle" onClick={handleClickThreeDots}>
          <img className="dropdown-icon" alt="Dropdown" src={dots} />
        </div>
      </div>
    </Fragment>
  );
}

export default Header;
