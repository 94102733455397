import React, { useContext } from "react";
import "./IconBanner.css";
import DiffIcon from "../../InfoPages/components/DiffIcon";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AppContext } from "../context/AppContext";

function IconBanner({
  difficulty,
  price,
  estTime,
  players,
  id,
  dutch,
  english,
}) {
  const { language } = useContext(AppContext);
  return (
    <div className="iconBanner">
      <div className="iconsWrapper">
        <div className="iconInfoWrapper">
          <DiffIcon diff={difficulty} id={id} />
          <p>{language === "NL" ? "Moeilijkheid" : "Difficulty"}</p>
        </div>
        <div className="iconInfoWrapper">
          <div className={`bigIcon ${id}-color`}>
            <FontAwesomeIcon icon={faClock} />
          </div>{" "}
          <p>
            {estTime} {language === "NL" ? "minuten" : "minutes"}
          </p>
        </div>
        <div className="iconInfoWrapper">
          <div className={`bold ${id}-color`}>
            {language === "NL" ? "Taal" : "Language"}
          </div>
          <p>
            {dutch ? (language === "NL" ? "Nederlands" : "Dutch") : ""}
            {dutch && english ? " & " : ""}
            {english ? (language === "NL" ? "Engels" : "English") : ""}
          </p>
        </div>
      </div>
    </div>
  );
}

export default IconBanner;
