import React from "react";
import "./Screenshots.css";

function Screenshots({ id, gifName }) {
  const fullElements = [];
  const thumbnailElements = [];

  const handleThumbnailClick = (i) => {
    const all_bigs = document.querySelectorAll(
      ".info-screenshots .big-picutures > img"
    );
    all_bigs.forEach((img) => (img.style.display = "none"));
    all_bigs[i].style.display = "block";

    const all_thumbs = document.querySelectorAll(
      ".info-screenshots .thumbnails > div"
    );
    all_thumbs.forEach((img) => img.classList.remove("active"));
    all_thumbs[i].classList.add("active");
  };

  for (let i = 0; i < 8; i++) {
    try {
      thumbnailElements.push(
        <div
          className={i === 0 ? "active" : ""}
          onClick={() => handleThumbnailClick(i)}
          key={i}
        >
          <img
            id={`screenshot${i}`}
            src={require(`./screenshots/${id}/thumbnail${i}.jpg`).default}
            alt=""
          />
        </div>
      );
    } catch {}
  }

  for (let j = 0; j < 8; j++) {
    try {
      fullElements.push(
        <img
          src={require(`./screenshots/${id}/full${j + 1}.jpg`).default}
          style={{ display: "none" }}
          alt=""
          key={j}
        />
      );
    } catch {}
  }

  return (
    <div className="info-screenshots">
      <div className="big-picutures">
        <img
          alt=""
          src={require(`../../EscaperoomsPage/img/${gifName}`).default}
        />
        {fullElements}
      </div>
      <div className="thumbnails">{thumbnailElements}</div>
    </div>
  );
}

export default Screenshots;
