import React, { useContext, useEffect } from "react";
import "./HomepageBody.css";
import { Link } from "react-router-dom";
import logo from "./img/escape_mystery_logo_1.png";
import { Helmet } from "react-helmet";

import { AppContext } from "../shared/context/AppContext";
// import { BrowserRouter as Router, Link, Route } from "react-router"

function HomepageBody() {
  const { language, setActiveHeader } = useContext(AppContext);

  useEffect(() => {
    setActiveHeader("home");
  }, []);

  return (
    <div className="page" id="homepage">
      <Helmet>
        <title>
          {language === "NL"
            ? "Online virtuele escape rooms"
            : "Online virtual escape rooms"}{" "}
          | Escape Mystery
        </title>
      </Helmet>
      <section className="page-content">
        <img
          src={logo}
          id="frontpage-logo"
          alt="Escape Rooms van Escape Mystery"
        ></img>
        <h2 className="centeralign">
          {language === "NL"
            ? "Spannende virtuele escape rooms in 360"
            : "Exciting online virtual escape rooms in 360"}
          &#176;
        </h2>

        <Link to="/escaperooms">
          <div className="blue-button vert-margin width100">
            {language === "NL"
              ? "Bekijk onze escape rooms"
              : "Our escape rooms"}
          </div>
        </Link>

        {/* <Carousel /> */}
      </section>
    </div>
  );
}

export default HomepageBody;
