import React, { useContext } from "react";
import "./EscapeRoomTile.css";
import {
  faEuroSign,
  faClock,
  faShoppingCart,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";

import { AppContext } from "../../shared/context/AppContext";

function EscapeRoomTile({
  title,
  titleEN,
  estTime,
  price,
  text,
  textEN,
  linkto,
  live,
  gifName,
  picName,
}) {
  const history = useHistory();
  const { language } = useContext(AppContext);

  const redirectToInfo = () => {
    if (live) {
      history.push(`/escaperooms/info/${linkto}`);
    }
  };

  return (
    <div className="EscapeRoomTile">
      <div className="innerERtile">
        <div className="ERpicture" onClick={redirectToInfo}>
          <img
            alt={title}
            className="gif-tile-img"
            src={require(`../img/${gifName}`).default}
          />
          <img
            alt={title}
            className="static-tile-img"
            src={require(`../img/${picName}`).default}
          />
        </div>
        <div className="ERinfo">
          <div className="infoItem">
            <FontAwesomeIcon icon={faClock} /> {estTime}{" "}
            {language === "NL" ? "minuten" : "minutes"}
          </div>

          <div className="infoItem">
            <FontAwesomeIcon icon={faEuroSign}></FontAwesomeIcon>
            {price > 0
              ? `${price} / team`
              : language === "NL"
              ? "Gratis!"
              : "Free!"}
          </div>
        </div>
        <div className="ERdescr" onClick={redirectToInfo}>
          <h2 className="ERtitle">{language === "NL" ? title : titleEN}</h2>
          <p className="ERtext">{language === "NL" ? text : textEN}</p>
        </div>
        {live ? (
          <div className="tileButtons">
            <Link to={`/escaperooms/info/${linkto}`}>
              <div className={`blue-button`}>
                <div className="buttonIconRooms">
                  <FontAwesomeIcon icon={faInfoCircle}> </FontAwesomeIcon>{" "}
                </div>
                Info
              </div>
            </Link>

            {price !== 0 ? (
              <Link to={`/escaperooms/pay/${linkto}`}>
                <div className={`blue-button`}>
                  <div className="buttonIconRooms">
                    <FontAwesomeIcon icon={faShoppingCart}> </FontAwesomeIcon>{" "}
                  </div>
                  {language === "NL" ? "Kopen" : "Buy"}
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="tileButtons">
            <div className={`blue-button button-inactive`}>
              {language === "NL" ? "Komt binnenkort!" : "Coming soon!"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EscapeRoomTile;
