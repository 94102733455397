import React from "react";
import { Helmet } from "react-helmet";

function AlgemeneVoorwaarden() {
  return (
    <div className="page">
      <Helmet>
        <title>Algemene voorwaarden | Escape Mystery</title>
      </Helmet>
      <section className="page-content">
        <h2>Algemene Voorwaarden Escape Mystery</h2>
        <div className="textClean">
          <h3>Artikel 1: Definities</h3>
          <ol>
            <li>
              <u>Ondernemer:</u> de natuurlijke personen die toegang tot de
              digitale inhoud aan Deelnemer aanbiedt, te weten Escape Mystery
              VOF, KVK-nummer: 81659385. BTW nummer NL862173267B01;
            </li>
            <li>
              <u>Deelnemer:</u> groep mensen (vanaf 1 persoon) die
              tegelijkertijd en gezamenlijk deelneemt aan één sessie van een
              Escape Mystery;
            </li>
            <li>
              <u>Escape Mystery:</u> een digitaal en online spel waarin puzzels
              opgelost worden (online escape room);
            </li>
            <li>
              <u>Overeenkomst op afstand:</u> een overeenkomst die tussen de
              ondernemer en de consument wordt gesloten in het kader van een
              georganiseerd systeem voor verkoop op afstand van digitale inhoud
              (een Escape Mystery), waarbij tot en met het sluiten van de
              overeenkomst uitsluitend of mede gebruik gemaakt wordt van één of
              meer technieken voor communicatie op afstand;
            </li>
          </ol>
          <h3>Artikel 2: Toepasselijkheid</h3>
          <ol>
            <li>
              Deze algemene voorwaarden zijn van toepassing op elk aanbod van de
              ondernemer en op elke tot stand gekomen overeenkomst op afstand
              tussen Ondernemer en Deelnemer.
            </li>
            <li>
              Voordat de overeenkomst op afstand wordt gesloten, wordt de tekst
              van deze algemene voorwaarden aan de Deelnemer beschikbaar gesteld
              via www.escapemystery.nl/voorwaarden.
            </li>
          </ol>
          <h3>Artikel 3: Aanbiedingen en offertes</h3>
          <ol>
            <li>
              Elk aanbod bevat zodanige informatie, dat voor de Deelnemer
              duidelijk is wat de rechten en verplichtingen zijn, die aan de
              aanvaarding van het aanbod zijn verbonden.
            </li>
            <li>
              Alle aanbiedingen en offertes en overige uitingen van Ondernemer
              zijn vrijblijvend, tenzij door Ondernemer schriftelijk anders is
              aangegeven. Indien een aanbod een beperkte geldigheidsduur heeft
              of onder voorwaarden geschiedt, wordt dit nadrukkelijk in het
              aanbod vermeld.
            </li>
            <li>
              Ondernemer behoudt zich het recht voor om een kortingsactie op elk
              moment te beëindigen of aan te passen.
            </li>
            <li>
              Betaling door Deelnemer geschiedt voorafgaand aan een Escape
              Mystery middels één van de aangegeven betalingsmethoden.
            </li>
          </ol>
          <h3>Artikel 4: Annuleren</h3>
          <ol>
            <li>
              Gezien de aard van dit online product kan na de aankoop van
              toegang tot een Escape Mystery de aankoop en betaling niet meer
              worden geannuleerd.
            </li>
          </ol>
          <h3>Artikel 5: Overmacht</h3>
          <ol>
            <li>
              In aanvulling op het bepaalde in artikel 6:75 BW geldt dat een
              tekortkoming van de Ondernemer in de nakoming van enige
              verplichting jegens de Deelnemer niet aan de Ondernemer kan worden
              toegerekend in geval van een van de wil van Ondernemer
              onafhankelijke omstandigheid, waardoor de nakoming van zijn
              verplichtingen jegens de Deelnemer geheel of gedeeltelijk wordt
              verhinderd of waardoor de nakoming van zijn verplichtingen in
              redelijkheid niet van de Ondernemer kan worden verlangd. Tot die
              omstandigheden worden mede gerekend wanprestaties van hulppersonen
              of andere derden, stroomstoringen, computervirussen en andere
              technische problemen.
            </li>
          </ol>
          <h3>Artikel 6: Aansprakelijkheid</h3>
          <ol>
            <li>
              Deelname aan een Escape Mystery geschiedt op eigen risico.
              Ondernemer aanvaardt geen aansprakelijkheid voor schade ten
              gevolge van ongeval, verlies of diefstal veroorzaakt aan
              deelnemers tijdens het arrangement.
            </li>
            <li>
              Het is voor Ondernemer niet mogelijk enige aansprakelijkheid te
              aanvaarden voor schade of letsel, berokkend aan Deelnemer aan een
              door Ondernemer georganiseerd arrangement, ongeacht de oorzaak van
              deze schade of dit letsel.
            </li>
            <li>
              Evenmin is Ondernemer aansprakelijk voor verlies van eigendommen.
            </li>
          </ol>
          <h3>Artikel 7: Geheimhouding</h3>
          <ol>
            <li>
              Om elke Escape Mystery ook voor andere (mogelijke) spelers leuk te
              houden wordt de Deelnemer verzocht geheimhouding te betrachten
              omtrent alle oplossingen en geheimen. Publiceren van antwoorden
              door Deelnemer is dan ook niet toegestaan. Over de ervaring
              vertellen zonder spoilers mag natuurlijk wel, graag zelfs!
            </li>
          </ol>
          <h3>Artikel 8: Privacy en gegevensbescherming</h3>
          <ol>
            <li>
              Aan de Ondernemer verstrekte persoonsgegevens worden zo zorgvuldig
              mogelijk verwerkt en alleen met expliciete toestemming wordt
              Deelnemer ingeschreven voor nieuwsbrieven. Voor meer details zie
              het Privacy Statement.
            </li>
          </ol>
          <h3>Artikel 9: Klachten</h3>
          <ol>
            <li>
              Indien de Deelnemer een klacht heeft kan hij deze kenbaar maken
              via info@escapemystery.nl. Ondernemer reageert zo snel mogelijk,
              maar in ieder geval binnen 14 dagen.
            </li>
            <li>
              Klachten over de uitvoering van de overeenkomst moeten binnen
              bekwame tijd nadat de Deelnemer de gebreken heeft geconstateerd,
              volledig en duidelijk omschreven worden ingediend bij de
              Ondernemer.
            </li>
            <li>
              Bij de Ondernemer ingediende klachten worden binnen een termijn
              van 14 dagen gerekend vanaf de datum van ontvangst beantwoord. Als
              een klacht een voorzienbaar langere verwerkingstijd vraagt, wordt
              door de ondernemer binnen de termijn van 14 dagen geantwoord met
              een bericht van ontvangst en een indicatie wanneer de consument
              een meer uitvoerig antwoord kan verwachten.
            </li>
            <li>
              De Deelnemer dient de Ondernemer in ieder geval 4 weken de tijd te
              geven om de klacht in onderling overleg op te lossen. Na deze
              termijn ontstaat een geschil dat vatbaar is voor de
              geschillenregeling.
            </li>
          </ol>
          <h3>Artikel 10: Geschillen</h3>
          <ol>
            <li>
              Op overeenkomsten tussen de Ondernemer en de Deelnemer waarop deze
              algemene voorwaarden betrekking hebben, is uitsluitend Nederlands
              recht van toepassing.
            </li>
          </ol>
        </div>
      </section>
    </div>
  );
}

export default AlgemeneVoorwaarden;
