import React, { useContext, useEffect, useState } from "react";
import EscapeRoomTile from "./components/EscapeRoomTile";
import escapeDataImport from "./components/EscapeRoomData";
import "./EscapeRooms.css";
import { Helmet } from "react-helmet";
import { AppContext } from "../shared/context/AppContext";

function EscapeRooms() {
  const { setActiveHeader, dotcom, language } = useContext(AppContext);
  const [escapeData, setEscapeData] = useState([]);

  useEffect(() => {
    setActiveHeader("escaperooms");
  }, []);

  useEffect(() => {
    setEscapeData(escapeDataImport);
    if (dotcom || language === "EN") {
      let escapeData2 = escapeDataImport.filter(
        (escaperoom) => escaperoom.english === true
      );
      setEscapeData(escapeData2);
    } else {
      let escapeData2 = escapeDataImport.sort((a, b) =>
        a.order > b.order ? 1 : -1
      );
      setEscapeData(escapeData2);
    }
  }, [setEscapeData, escapeDataImport, dotcom, language]);

  const escapeRoomTiles = escapeData.map((ER) => {
    return (
      <EscapeRoomTile
        title={ER.title}
        titleEN={ER.titleEN}
        difficulty={ER.difficulty}
        estTime={ER.estTime}
        playURL={ER.URL}
        price={ER.price}
        text={ER.text}
        textEN={ER.textEN}
        id={ER.id}
        key={ER.id}
        linkto={ER.linkto}
        players={ER.players}
        live={ER.live}
        themeColor={ER.themeColor}
        gifName={ER.gifName}
        picName={ER.picName}
      />
    );
  });

  return (
    <div className="page">
      <Helmet>
        <title>
          {language === "NL"
            ? "Alle escape rooms | Escape Mystery"
            : "All escape rooms | Escape Mystery"}
        </title>
      </Helmet>
      <section className="light-bg">
        <section className="page-content" id="escaperoom-tiles">
          {escapeRoomTiles}
        </section>
      </section>
    </div>
  );
}

export default EscapeRooms;
