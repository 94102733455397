import React, { useState, useContext, useEffect } from "react";
import "./Klassement.css";
import { Helmet } from "react-helmet";
import { AppContext } from "../shared/context/AppContext";
import KlassementTable from "./KlassementTable";
import DemoKlassementData from "./DemoKlassementData";

import PageNotFound from "../PageNotFound/PageNotFound";
import { useParams } from "react-router";

function Klassement() {
  const { language } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const { naam } = useParams();
  const [displayName, setDisplayName] = useState();
  const [displayNameEN, setDisplayNameEN] = useState();
  const [subTitle, setSubTitle] = useState();
  const [subTitleEN, setSubTitleEN] = useState();
  const [klassementData, setKlassementData] = useState();

  useEffect(() => {
    if (naam === "demo") {
      setDisplayName("Klassement Bedrijf X");
      setDisplayNameEN("Rankings Bedrijf X");
      setSubTitle("Wie puzzelt het snelste!?");
      setSubTitleEN("Who's the quickest puzzle solver?");
      setKlassementData(DemoKlassementData);
      setIsLoading(false);
    } else {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(
            process.env.REACT_APP_BACKENDURL + "/website/rankings/getranking",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                rankingId: naam,
              }),
            }
          );
          const data = await response.json();

          if (!response.ok) {
            throw new Error(data.errorMsg);
          }
          setDisplayName(data.displayname);
          setDisplayNameEN(data.displaynameEN);
          setSubTitle(data.subTitle);
          setSubTitleEN(data.subTitleEN);
          setKlassementData(data.rankingData);
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          console.log(err.message);
          setNotFound(true);
        }
      };
      fetchData();
    }
  }, [naam]);

  if (isLoading) {
    return (
      <div id="rangkings-page">
        <section className="page-content">
          <div className="PageContentWrapper">
            <h2 id="loading">Loading...</h2>
          </div>
        </section>
      </div>
    );
  } else if (notFound) {
    return <PageNotFound />;
  } else {
    return (
      <div className="page" id="rankings-page">
        <Helmet>
          <title>
            {language === "NL"
              ? `${displayName} | Escape Mystery`
              : `${displayNameEN} | Escape Mystery`}
          </title>
        </Helmet>
        <section className="page-content">
          <h2 className="centeralign">
            {language === "NL" ? displayName : displayNameEN}
          </h2>
          <div className="PageSubTitleText" id="EscapeRoomsSubTitle">
            {language === "NL" ? subTitle : subTitleEN}
          </div>
          <div id="rankings-page-table-wrapper">
            {klassementData ? <KlassementTable data={klassementData} /> : ""}
          </div>
          {naam === "demo" ? (
            <p>
              {language === "NL"
                ? "Dit is een demonstratie voor hoe een privé bedrijfsklassement er uit kan zien. Zo'n privé pagina kan met een zelf gekozen wachtwoord beveiligd worden. Teamnamen zijn voor elk team zelf te kiezen, maar kunnen ook van tevoren naar wens ingesteld worden."
                : "This is a demonstration of what a private company ranking can look like. Such a private page can be protected with a password of your choice. Team names can be chosen by each team, but can also be set in advance as desired."}
            </p>
          ) : (
            ""
          )}
        </section>
      </div>
    );
  }
}

export default Klassement;
